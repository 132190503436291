.ContactFormWrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContactFormCont {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: #ededed;
    padding: 40px;
    border-radius: 12px;

    opacity: 0;
}

.FormField {
    display: flex;
    flex-direction: column;
}

.FormField>label {
    margin-bottom: 8px;
}

.FormField>input {
    background-color: #e4e4e4;
    height: 40px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #e4e4e4;
    outline: none;
}

.FormField>textarea {
    background-color: #e4e4e4;
    height: 300px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #e4e4e4;
    outline: none;
}

.FormField input:focus,
.FormField textarea:focus {
    border-color: #bc2026;

    border: 2px solid #bc2026;
    outline: none;

    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.FormSubmitCont {
    display: flex;
    justify-content: flex-start;
}

.FormSubmitButton {
    width: 85px;
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    background-color: #bc2026;
    color: white;

    transition: 0.5s ease-in, transform 0.3s ease, box-shadow 0.3s ease;
}

.FormSubmitButton:hover {
    cursor: pointer;
    background-color: #ca2e33;

    transform: translateY(-5px);
    /* Raise the button */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* Add a shadow */
}

.Popup-outer{
    position: fixed;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #00000080;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Popup-inner{
   background-color: #ffffff;
   width: 80%;
   padding: 20px;
   display: flex;
   justify-content: center;
}