.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.LayOutStyle {
  height: calc(100vh - 70px);
  overflow-y: scroll;
  overflow-x: hidden;

}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* animations */

/* Slide-in animation when the element comes into view */
@keyframes slideInLeft {
  from {
    transform: translateX(-30px);
    /* Start from the left */
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(30px);
    /* Start from the right */
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}


/* Define the initial and final state of the text */
@keyframes slideUp {
  0% {
    transform: translateY(100px);
    /* Start from below the viewport */
    opacity: 0;
    /* Start invisible */
  }

  100% {
    transform: translateY(0);
    /* End at its original position */
    opacity: 1;
    /* Become fully visible */
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.1);
    /* Start small */
    opacity: 0;
    /* Start fully transparent */
  }

  100% {
    transform: scale(1);
    /* End at original size */
    opacity: 1;
    /* End fully opaque */
  }
}

/* Animation class */
.zoom-in-animation {
  animation: zoomIn 0.8s ease-out;
  opacity: 1 !important;
  /* Adjust duration and timing function as needed */
}


/* Add a class for the animated text */
.animate-slide-up {
  animation: slideUp 0.4s ease-out forwards;
  opacity: 1 !important;
  /* Apply the animation */
}

/* Apply animations when in view */
.animate-from-left {
  animation: slideInLeft 0.8s ease-out forwards;
  opacity: 1 !important;
}

.animate-from-right {
  animation: slideInRight 0.8s ease-out forwards;
  opacity: 1 !important;
}

/* Optionally, for the whole section, if you want to animate everything */
.animate-slide-in {
  animation: slideInLeft 0.8s ease-out forwards;
  opacity: 1 !important;
}