.ProductCategoryWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #bc2026; */
    padding: 22px 0px;
}

.ProductCategoyHeaderText {
    display: flex;
    justify-content: center;
    /* color: white; */
    font-size: 30px;
    font-weight: 600;
    padding: 12px 0px;
}

.ProductCategoryCont {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 12px;
}

.ProductCatItem {
    width: 250px !important; 
    flex: 0 1 calc(20% - 8px);
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: 600;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    margin: 4px;

    transition: transform 0.3s ease, scale 0.3s ease;
    /* Smooth zoom-in transition */
    transform-style: preserve-3d;
    perspective: 1000px;
    transform-origin: center;
}

.ProductCatItem:hover {

    transform: perspective(1000px) rotateX(10deg) rotateY(-10deg) scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);

    transform: scale(1.1);
    z-index: 5;
}

.ProductCatItem:hover {
    transform: rotateY(360deg);
    /* Rotate around the Y-axis */
}

.ProductCatItem:hover::before {
    opacity: 0.6;
    /* Slightly darken the overlay on hover */
}

.ProductItemText {
    position: relative;
    /* Ensures the text is above the overlay */
    color: white;
    z-index: 2;

    text-align: center;
    transform: translateZ(20px);
    /* Slightly elevate the text */
    font-weight: bold;
}

.SuspensionPart {
    position: relative;
    /* Needed for the pseudo-element */
    background-image: url('../../../images/SuspensionProdCat.webp');
}

.SuspensionPart::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    z-index: 1;
    /* Places it beneath the text */
}

.BrakePart {
    position: relative;
    /* Needed for the pseudo-element */
    background-image: url('../../../images/BreakPartProdCat.webp');
}

.BrakePart::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    z-index: 1;
    /* Places it beneath the text */
}

.CletchPart {
    position: relative;
    /* Needed for the pseudo-element */
    background-image: url('../../../images/CletchProdCat.webp');
}

.CletchPart::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    z-index: 1;
    /* Places it beneath the text */
}

.EnginePart {
    position: relative;
    /* Needed for the pseudo-element */
    background-image: url('../../../images/EnginePartProdCat.webp');
}

.EnginePart::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    z-index: 1;
    /* Places it beneath the text */
}

.OtherPart {
    position: relative;
    /* Needed for the pseudo-element */
    background-image: url('../../../images/OtherPartProdCat.webp');
}

.OtherPart::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    z-index: 1;
    /* Places it beneath the text */
}

.ProductCategoryCont {
    opacity: 0;
}


.ProductCategoryCont div:nth-child(1) {
    animation-delay: 0.4s;
}

.ProductCategoryCont div:nth-child(2) {
    animation-delay: 0.4s;
}

.ProductCategoryCont div:nth-child(3) {
    animation-delay: 0.4s;
}

.ProductCategoryCont div:nth-child(4) {
    animation-delay: 0.4s;
}


/* Define the initial and final state of the text */
@keyframes slideUp {
    0% {
        transform: translateY(100px);
        /* Start from below the viewport */
        opacity: 0;
        /* Start invisible */
    }

    100% {
        transform: translateY(0);
        /* End at its original position */
        opacity: 1;
        /* Become fully visible */
    }
}

/* Add a class for the animated text */
.animate-slide-up {
    animation: slideUp 0.4s ease-out forwards;
    opacity: 1;
    /* Apply the animation */
}

/* Responsive design for medium screens */
@media (max-width: 1024px) {
    .ProductCatItem {
        flex: 0 1 calc(33.33% - 8px);
        /* 3 items per row */
    }
}

/* Responsive design for small screens */
@media (max-width: 768px) {
    .ProductCatItem {
        flex: 0 1 calc(50% - 8px);
        /* 2 items per row */
    }


    .ProductCategoryCont {
        opacity: 1;
    }
}

/* Responsive design for very small screens */
@media (max-width: 480px) {
    .ProductCatItem {
        flex: 0 1 calc(100% - 8px);
        /* 1 item per row */
    }

    .ProductCategoryCont {
        opacity: 1;
    }
}