.NavBarWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;

    height: 80px;
    padding: 8px;
}

.NavBarLogoCont {
    width: 100%;
    display: flex
;
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
}
.NavBarLogoImg {
    width: 280px;
}

.NavBarLinkCont {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.NavLinks {
    padding: 8px 14px;
    color: #999999;
    cursor: pointer;
}

.NavLinks:hover {
    background-color: #f0f0f0;
}

.NavLinks.active {
   
    color: #000000;
    border-bottom: #000000;
    border-width: 2px;
    border: 5px solid #000000;
    border-width: 0px 0px 5px 0px;
}

.NavBarMobLinkCont {
    display: none;
}


/* navBraMenu */

.NavBarMenu {
    position: absolute;
    width: 100%;
    height: 100vh;

    top: 0px;
    padding: 50px 0px;

    display: flex;
    flex-direction: column;
    background-color: white;

    z-index: 20;
}

.NavBarMenu>div {
    color: black;
}

.NavBarMenuCloseCont {
    display: flex;
    justify-content: flex-end;
    padding: 0px 30px;
}

.NavBarMenuItemsCont {
    padding: 50px;
}

.NavBarMenuItems {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media (max-width:960px) {
    .NavBarLinkCont {
        display: none;
    }

    .NavBarMobLinkCont {
        width: 9%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


@media (max-width:650px) {
    .NavBarLogoImg {
        width: 210px;
    }

    .NavBarLogoCont {
        width: 100%;
    }
}

@media (max-width:460px) {
    .NavBarMobLinkCont {
        width: 10%;
    }
}