.MainBrandsWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: hwb(358 13% 26%); */
    background-color: #ffffff;

    padding: 24px 0px;
}

.MainBrandsCont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width:860px) {}