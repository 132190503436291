.AboutEnhanceWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 22px 0px;

    height: 360px;

    /* background-image: url('../../../images/bannerBg.webp'); */
    background-image: url('../../../images/gaffiti.avif');
    /* background-image: url('../../../images/HomeBanner2.jpeg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    background-color: #000000;

    position: relative;

}

.AboutEnhanceWrap::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black overlay */
    z-index: 2;
    /* Places it beneath the text */
}

.AboutEnhanceLeft,
.AboutEnhanceRightText {
    color: white;
    z-index: 2;
}

@media (max-width:1130px) {

    .HistoryBannerLeftWrap,
    .HistoryBannerRightWrap {
        width: 50%;
    }

    .HistoryBannerRightCont {
        font-size: 20px;
    }

    .HistoryBannerLeftCont {
        font-size: 40px;
    }
}

@media (max-width:960px) {
    .AboutEnhanceWrap {
        flex-direction: column;
        height: max-content;
    }

    .HistoryBannerLeftWrap,
    .HistoryBannerRightWrap {
        width: 100%;
    }

    .HistoryBannerLeftCont,
    .HistoryBannerRightCont {
        text-align: center;
    }

    .HistoryBannerLeftCont {
        padding: 16px 0px;
    }
}

@media (max-width:460px) {
    .HistoryBannerLeftCont {
        font-size: 28px;
    }

    .HistoryBannerRightCont {
        font-size: 15px;
    }
}