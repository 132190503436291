.HistoryBannerWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 22px 0px;

    height: 360px;

    background-image: url('../../../images/HomeHistory.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    background-color: #000000;

    position: relative;

}

.HistoryBannerWrap::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Semi-transparent black overlay */
    z-index: 1;
    /* Places it beneath the text */
}

.HistoryBannerLeftWrap {
    width: 52%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.HistoryBannerLeftCont {
    width: 95%;
    font-size: 50px;
    font-weight: 800;
    color: white;
    line-height: 1.2;
}

.HistoryBannerRightWrap {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.HistoryBannerRightCont {
    width: 95%;
    font-size: 22px;
    font-weight: 400;
    color: white;
    line-height: 1.5;
}

.AboutEnhanceRightText {
    font-size: 20px;
}

@media (max-width:1130px) {

    .HistoryBannerLeftWrap,
    .HistoryBannerRightWrap {
        width: 50%;
    }

    .HistoryBannerRightCont {
        font-size: 20px;
    }

    .HistoryBannerLeftCont {
        font-size: 40px;
    }
}

@media (max-width:960px) {
    .HistoryBannerWrap {
        flex-direction: column;
        height: max-content;
    }

    .HistoryBannerLeftWrap,
    .HistoryBannerRightWrap {
        width: 100%;
    }

    .HistoryBannerLeftCont,
    .HistoryBannerRightCont {
        text-align: center;
    }

    .HistoryBannerLeftCont {
        padding: 16px 0px;
    }
}

@media (max-width:460px) {
    .HistoryBannerLeftCont {
        font-size: 28px;
    }

    .HistoryBannerRightCont {
        font-size: 15px;
    }
}