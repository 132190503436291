.HomeCarouselItem {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    border-radius: 12px;
    padding: 12px 4px;
}

.HomeCarouselImg {
    width: 150px;
    object-fit: cover;
}


/* Add this to your CSS file */
.carousel-wrapper {
    overflow: hidden;
    width: 100%;
}

.carousel-inner {
    display: flex;
    animation: scroll 20s linear infinite;
    /* Adjust the duration for the scroll speed */
}

.carousel-item {
    flex: 0 0 auto;
    width: 100%;
    /* or your desired width */
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}